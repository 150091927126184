import React from "react";
import { Accordion, Form } from "react-bootstrap";
import { Duration, PriceRange } from "../../utils/data";

const Filters = ({ setSelectedDuration, setSelectedPriceRange }) => {
  const handleDurationChange = (event) => {
    const { value, checked } = event.target;
    setSelectedDuration((prev) =>
      checked ? [...prev, value] : prev.filter((dur) => dur !== value)
    );
  };

  const handlePriceChange = (event) => {
    const { value, checked } = event.target;
    setSelectedPriceRange((prev) =>
      checked ? [...prev, value] : prev.filter((price) => price !== value)
    );
  };

  return (
    <div className="side_bar">
      {/* Price Range */}
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Price Range</Accordion.Header>
          <Accordion.Body>
            {PriceRange.map((price, inx) => (
              <Form.Check
                key={inx}
                type="checkbox"
                id={price}
                label={price}
                value={price}
                onChange={handlePriceChange}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Duration */}
      {/* <Accordion defaultActiveKey="2">
        <Accordion.Item eventKey="2">
          <Accordion.Header>Duration</Accordion.Header>
          <Accordion.Body>
            {Duration.map((duration, inx) => (
              <Form.Check
                key={inx}
                type="checkbox"
                id={duration}
                label={duration}
                value={duration}
                onChange={handleDurationChange}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
    </div>
  );
};

export default Filters;
