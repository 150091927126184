import React, { useEffect } from "react";
import "./whatsAppButton.css"; // Make sure you have this file for the styles

const WhatsAppButton = () => {
  useEffect(() => {
    // Make sure the button is rendered immediately
    const button = document.querySelector(".whatsapp-button");
    if (button) {
      button.style.display = "block";
    }
  }, []);

  return (
    <a
      href="https://wa.me/+971582058098?text=Hello, I'm interested in Desert Safari please tell me more about your pacakages." // Replace with your number and message
      className="whatsapp-button"
      aria-label="WhatsApp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fab fa-whatsapp whatsapp-icon"></i>
    </a>
  );
};

export default WhatsAppButton;
