import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  ListGroup,
  Row,
  Alert,
} from "react-bootstrap";
import image from "../../assets/images/new/contact-us.png";
import { Helmet } from "react-helmet"; // Import Helmet
import "./contact.css"; // Import your CSS file

const Contact = () => {
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState("");

  useEffect(() => {
    document.title = "Contact - SuperSafariDubai";
    window.scroll(0, 0);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails({ ...formDetails, [name]: value });
  };

  const validateForm = () => {
    let errors = {};
    let valid = true;

    if (!formDetails.name.trim()) {
      errors.name = "Name is required";
      valid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(formDetails.name)) {
      errors.name = "Name should only contain letters";
      valid = false;
    }

    if (!formDetails.email) {
      errors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formDetails.email)) {
      errors.email = "Email is invalid";
      valid = false;
    }

    if (!formDetails.phone) {
      errors.phone = "Phone number is required";
      valid = false;
    } else if (!/^\d+$/.test(formDetails.phone)) {
      errors.phone = "Phone number must be a valid number";
      valid = false;
    }

    if (!formDetails.message.trim()) {
      errors.message = "Message is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const response = await fetch(
        "https://formsubmit.co/ajax/76f19ba266aa9c87c41a3c2f857ec510",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(formDetails),
        }
      );

      const result = await response.json();
      if (result.success) {
        setNotification("Your inquiry has been sent successfully!");
        setFormDetails({ name: "", email: "", phone: "", message: "" });
      } else {
        setNotification("Failed to send your inquiry. Please try again.");
      }
    }
  };

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Contact - SuperSafariDubai</title>
        <meta
          name="description"
          content="Get in touch with us for your Dubai Desert Safari inquiries. Contact us for bookings, questions, and customized safari experiences."
        />
        <link rel="canonical" href="https://supersafaridubai.com/contact" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact - SuperSafariDubai" />
        <meta
          property="og:description"
          content="Get in touch with us for your Dubai Desert Safari inquiries. Contact us for bookings, questions, and customized safari experiences."
        />
        <meta
          property="og:url"
          content="https://supersafaridubai.com/contact"
        />
        <meta
          property="og:image"
          content="https://supersafaridubai.com/path-to-your-image.jpg"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact - SuperSafariDubai" />
        <meta
          name="twitter:description"
          content="Get in touch with us for your Dubai Desert Safari inquiries. Contact us for bookings, questions, and customized safari experiences."
        />
        <meta
          name="twitter:image"
          content="https://supersafaridubai.com/path-to-your-image.jpg"
        />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ContactPage",
              "name": "Contact - SuperSafariDubai",
              "description": "Contact us for inquiries about your Dubai Desert Safari experience.",
              "url": "https://supersafaridubai.com/contact",
              "mainEntity": {
                "@type": "Organization",
                "name": "Dubai Desert Safari",
                "url": "https://supersafaridubai.com",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+971563564741",
                  "contactType": "Customer Service"
                }
              }
            }
          `}
        </script>
      </Helmet>

      <Breadcrumbs title="Contact" pagename="Contact" />
      <section className="contact pt-5">
        <Container>
          <Row>
            <Col md="12">
              <h1 className="mb-2 h1 font-bold">
                Contact Us for Your Dubai Desert Safari Adventure
              </h1>
              <p className="body-text mt-1">
                Have questions about our thrilling desert safari tours? Want to
                book your next adventure? Get in touch with us for more
                information or to customize your desert safari experience in
                Dubai!
              </p>
            </Col>
          </Row>

          {/* Contact Info Cards */}
          <Row className="py-5">
            <Col lg="4" md="6" className="mb-4 mb-lg-0">
              <Card className="border-0 shadow-sm rounded-3 mb-4">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-items-center my-2">
                    <div className="bg-info text-info bg-opacity-10 rounded-circle p-4 mb-2 icon-shadow icon-container">
                      <i className="bi bi-headset h3"></i>
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">Call Us</Card.Title>
                  <p className="mb-3 body-text">
                    Have any questions or need assistance? Give us a call and
                    we’ll help you with all the details for an unforgettable
                    desert safari experience.
                  </p>
                  <div className="d-block justify-content-between">
                    <a type="button" className="btn btn-light me-2 btn-sm">
                      <i className="bi bi-phone me-1"></i>
                      +971582058098
                    </a>
                    <a type="button" className="btn btn-light me-2 btn-sm">
                      <i className="bi bi-telephone me-1"></i>
                      +971582058098
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" md="6" className="mb-4 mb-lg-0">
              <Card className="border-0 shadow-sm rounded-3 mb-4">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-items-center my-2">
                    <div className="bg-danger text-danger bg-opacity-10 rounded-circle p-4 mb-2 icon-shadow icon-container">
                      <i className="bi bi-envelope h3"></i>
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">Email Us</Card.Title>
                  <p className="mb-3 body-text">
                    For any detailed inquiries or to make a reservation, drop us
                    an email. We'll get back to you with all the information you
                    need to plan your perfect desert safari.
                  </p>
                  <div className="d-block justify-content-between">
                    <a type="button" className="btn btn-light me-2 btn-sm">
                      <i className="bi bi-envelope me-2"></i>
                      info@supersafaridubai.com
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" md="12" className="mb-4 mb-lg-0">
              <Card className="border-0 shadow-sm rounded-3 mb-4">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-items-center my-2">
                    <div className="bg-warning text-warning bg-opacity-10 rounded-circle p-4 mb-2 icon-shadow icon-container">
                      <i className="bi bi-globe h3"></i>
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">Follow Us</Card.Title>
                  <p className="mb-3 body-text">
                    Stay updated with the latest news and offers on our desert
                    safaris. Follow us on social media for exclusive updates and
                    exciting photos of our adventures.
                  </p>
                  <div className="d-block justify-content-center">
                    <ListGroup horizontal className="justify-content-center">
                      <ListGroup.Item className="border-0">
                        <i className="bi bi-youtube"></i>
                      </ListGroup.Item>
                      <ListGroup.Item className="border-0">
                        <i className="bi bi-facebook"></i>
                      </ListGroup.Item>
                      <ListGroup.Item className="border-0">
                        <i className="bi bi-instagram"></i>
                      </ListGroup.Item>
                      <ListGroup.Item className="border-0">
                        <i className="bi bi-whatsapp"></i>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="py-5 align-items-center">
            <Col xl="6" md="6" className="d-none d-md-block">
              <img src={image} alt="contact" className="img-fluid me-3" />
            </Col>
            <Col xl="6" md="6">
              <Card className="bg-light p-4 border-0 shadow-sm">
                <div className="form-box">
                  <h1 className="h3 font-bold mb-4">
                    Inquire About Your Desert Safari
                  </h1>

                  {/* Notification alert */}
                  {notification && (
                    <Alert
                      variant={
                        notification.includes("successfully")
                          ? "success"
                          : "danger"
                      }
                      className="mb-3"
                    >
                      {notification}
                    </Alert>
                  )}

                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="6" className="mb-3">
                        <FloatingLabel
                          controlId="floatingName"
                          label="Name"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={formDetails.name}
                            onChange={handleInputChange}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md="6" className="mb-3">
                        <FloatingLabel
                          controlId="floatingEmail"
                          label="Email address"
                          className="mb-3"
                        >
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={formDetails.email}
                            onChange={handleInputChange}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md="6" className="mb-3">
                        <FloatingLabel
                          controlId="floatingPhone"
                          label="Phone number"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Phone"
                            name="phone"
                            value={formDetails.phone}
                            onChange={handleInputChange}
                            isInvalid={!!errors.phone}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md="6" className="mb-3">
                        <FloatingLabel
                          controlId="floatingMessage"
                          label="Message"
                          className="mb-3"
                        >
                          <Form.Control
                            as="textarea"
                            placeholder="Message"
                            name="message"
                            value={formDetails.message}
                            onChange={handleInputChange}
                            isInvalid={!!errors.message}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col className="d-grid">
                        <Button type="submit" className="btn btn-primary">
                          Send Message
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
