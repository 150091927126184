import React, { useEffect } from 'react';
import Lightroom from 'react-lightbox-gallery';
import GalleryImg1 from "../../assets/images/gallery/g1.jpg";
import GalleryImg3 from "../../assets/images/gallery/g3.jpg";
import GalleryImg4 from "../../assets/images/gallery/g4.jpg";
import GalleryImg6 from "../../assets/images/gallery/g6.jpg";
import GalleryImg7 from "../../assets/images/gallery/g7.jpg";
import { Helmet } from 'react-helmet';

const Gallery = () => {
  useEffect(() => {
    document.title = "Gallery - Desert Safari Dubai";
    window.scroll(0, 0);
  }, []);

  const images = [
    {
      src: GalleryImg1,
      desc: "Experience the breathtaking beauty of the Arabian Desert at sunset.",
      sub: "Captured by Adventure Enthusiast"
    },
    {
      src: GalleryImg3,
      desc: "Thrilling dune bashing in the golden sands of Dubai.",
      sub: "Dmitriy Frantsev"
    },
    {
      src: GalleryImg6,
      desc: "Camel rides at dusk, creating unforgettable memories.",
      sub: "Harry Cunningham"
    },
    {
      src: GalleryImg4,
      desc: "A traditional Bedouin camp, where culture meets comfort.",
      sub: "Liam Baldock"
    },
    {
      src: GalleryImg7,
      desc: "Delightful BBQ dinner under the starlit desert sky.",
      sub: "Verne Ho"
    },
    {
      src: GalleryImg6,
      desc: "Stunning views of the dunes during a desert safari adventure.",
      sub: "Hari Nandakumar"
    },
  ];

  const settings = {
    columnCount: {
      default: 3,
      mobile: 2,
      tab: 3
    },
    mode: "dark",
    enableZoom: false,
  };

  return (
    <>
           <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "ImageGallery",
        "name": "Desert Safari Dubai Gallery",
        "description": "A collection of stunning images from our Desert Safari experiences in Dubai.",
        "image": [
          "${GalleryImg1}",
          "${GalleryImg3}",
          "${GalleryImg4}",
          "${GalleryImg6}",
          "${GalleryImg7}"
        ]
      }
    `}
  </script>
     
      <Lightroom images={images} settings={settings} />
    </>
  );
}

export default Gallery;
