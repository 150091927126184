import React, { useState } from "react";
import "../Cards/card.css";
import { Card, ListGroup, Stack } from "react-bootstrap";

const PopularCard = ({ val }) => {
  // State to track if "Read More" is clicked
  const [showAll, setShowAll] = useState(false);

  // Handle toggle of showing more/less points
  const toggleShowAll = () => setShowAll(!showAll);

  return (
    <>
      <Card className="rounded-2 shadow-sm popular">
        <Card.Img
          variant="top"
          src={val.image}
          className="img-fluid"
          alt="image"
        />
        <Card.Body>
          <p className="reviwe">
            <Card.Title>{val.title}</Card.Title>

            {val.included.map((item, index) => (
              <ListGroup.Item
                className="border-0 pt-0 body-text d-flex included-text align-items-center"
                key={index}
              >
                <i className="bi bi-check-lg text-success h6 m-0"></i> {item}
              </ListGroup.Item>
            ))}
          </p>
        </Card.Body>
        <Card.Footer className="py-4">
          {val.afterDiscount ? (
            <p className="text-decoration-line-through custom-text">
              AED {val.price.toFixed(2)}
            </p>
          ) : (
            ""
          )}
          <Stack
            direction="horizontal"
            className="justify-content-between mt-3"
          >
            <p>
              From{" "}
              <b>
                {val.afterDiscount
                  ? val.afterDiscount.toFixed(2)
                  : val.price.toFixed(2)}
              </b>
            </p>
            <p>
              <div className="small-text">
                <span>
                  <i className="bi bi-star-fill me-1 border-0 me-1 text-warning"></i>
                  <i className="bi bi-star-fill me-1 border-0 me-1 text-warning"></i>
                  <i className="bi bi-star-fill me-1 border-0 me-1 text-warning"></i>
                  <i className="bi bi-star-fill me-1 border-0 me-1 text-warning"></i>
                  <i className="bi bi-star-fill me-1 border-0 me-1 text-warning"></i>
                </span>
                <span>{val.reviews} Reviews</span>
              </div>
            </p>
          </Stack>
        </Card.Footer>

        <a
          href={`https://api.whatsapp.com/send?phone=+971582058098&text=${encodeURIComponent(
            `Hello, I am interested in ${val.title}.`
          )}`}
          className="primaryBtn d-inline-block"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book Now
        </a>
      </Card>
    </>
  );
};

export default PopularCard;
