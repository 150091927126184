import React, { useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Card, Col, Container, Row } from "react-bootstrap";
import desertSafariImg from "../../assets/images/about/aboutimg.jpg";
import icons1 from "../../assets/images/icons/destination.png";
import icons2 from "../../assets/images/icons/best-price.png";
import icons3 from "../../assets/images/icons/quick.png";
import "./about.css";
import { Helmet } from "react-helmet";

const About = () => {
  useEffect(() => {
    document.title = "About Desert Safari Dubai";
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>About Desert Safari Dubai Experience</title>
        <meta
          name="description"
          content="Discover the thrilling Desert Safari Dubai experience. From dune bashing to camel rides, explore the vast Arabian desert and create unforgettable memories."
        />
        <link rel="canonical" href="https://supersafaridubai.com/about-us" />

        {/* Robots Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About Desert Safari Dubai" />
        <meta
          property="og:description"
          content="Explore the exciting Desert Safari Dubai experience. Enjoy activities like dune bashing, camel rides, and more."
        />
        <meta
          property="og:url"
          content="https://supersafaridubai.com/about-us"
        />
        <meta property="og:image" content={desertSafariImg} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Desert Safari Dubai" />
        <meta
          name="twitter:description"
          content="Experience the thrilling Desert Safari Dubai. From exhilarating activities to relaxing evenings under the stars, discover the best desert adventures."
        />
        <meta name="twitter:image" content={desertSafariImg} />

        {/* Viewport */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Language */}
        <meta http-equiv="content-language" content="en" />

        {/* Optional: Include schema.org JSON-LD for rich results */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "TouristAttraction",
              "name": "Desert Safari Dubai",
              "description": "Discover the thrilling Desert Safari Dubai experience. Enjoy activities like dune bashing, camel rides, and more.",
              "image": "${desertSafariImg}",
              "offers": {
                "@type": "Offer",
                "url": "https://supersafaridubai.com/about-us",
                "priceCurrency": "AED",
                "price": "89",
                "availability": "InStock",
                "itemCondition": "https://schema.org/NewCondition",
                "offers": {
                  "@type": "AggregateOffer",
                  "lowPrice": "49",
                  "highPrice": "150",
                  "offerCount": "100"
                }
              },
              "location": {
                "@type": "Place",
                "name": "Arabian Desert, Dubai",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "Dubai",
                  "addressCountry": "AE"
                }
              }
            }
          `}
        </script>
      </Helmet>

      <Breadcrumbs title="About Desert Safari Dubai" pagename="About us" />
      <section className="py-5">
        <Container>
          <Row>
            <Col md="8">
              <div className="about-content">
                <div className="about-image position-relative">
                  <img
                    src={desertSafariImg}
                    alt="Desert Safari"
                    className="img-fluid rounded-5"
                  />
                  <div className="about-image-content position-absolute top-50 end-0 p-md-4 p-3 rounded-5 shadow-sm">
                    <h3 className="h2 fw-bold text-white">
                      Experience the Thrill of the Desert
                    </h3>
                  </div>
                </div>
              </div>
              <h2 className="h2 font-bold pt-4 pb-2">
                Discover SuperSafariDubai
              </h2>
              <p className="body-text mb-2">
                Explore the vast Arabian desert with our top-rated Desert Safari
                Dubai experience. From exhilarating dune bashing to a peaceful
                sunset over the dunes, it's an adventure like no other.
              </p>
              <p className="body-text mb-2">
                Our safari includes camel rides, sandboarding, traditional
                Bedouin camps, and an unforgettable BBQ dinner under the stars.
                Let our expert guides lead you through a magical desert journey.
              </p>
              <p className="body-text mb-2">
                Whether you're looking for excitement or relaxation, Desert
                Safari Dubai offers something for everyone. Book today and
                create memories that will last a lifetime.
              </p>
            </Col>
            <Col md="4">
              <Card className="border-0 shadow-sm rounded-3 mb-4">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-items-center my-2">
                    <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                      <img src={icons1} alt="icon" className="img-fluid" />
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">
                    10+ Desert Locations
                  </Card.Title>
                  <p className="mb-2 body-text">
                    Explore multiple desert locations, each offering unique
                    views and activities for a complete desert adventure.
                  </p>
                </Card.Body>
              </Card>
              <Card className="border-0 shadow-sm rounded-3 mb-4">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-items-center my-2">
                    <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                      <img src={icons2} alt="icon" className="img-fluid" />
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">Best Prices</Card.Title>
                  <p className="mb-2 body-text">
                    Enjoy premium services at unbeatable prices. We guarantee
                    the best value for your money.
                  </p>
                </Card.Body>
              </Card>
              <Card className="border-0 shadow-sm rounded-3 mb-4">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-items-center my-2">
                    <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                      <img src={icons3} alt="icon" className="img-fluid" />
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">
                    Easy & Fast Booking
                  </Card.Title>
                  <p className="mb-2 body-text">
                    Our quick booking process ensures you don't miss out on this
                    extraordinary experience. Book in just a few clicks!
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
