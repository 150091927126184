import React, { useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Gallery from "../../components/Gallery/Gallery";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet"; // Import Helmet

const PhotoGallery = () => {
  useEffect(() => {
    document.title = "Gallery";
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Gallery - Desert Safari Dubai</title>
        <meta
          name="description"
          content="Explore stunning visuals from our Desert Safari Dubai experience. Discover breathtaking landscapes, exhilarating activities, and unforgettable moments."
        />
        <link rel="canonical" href="https://supersafaridubai.com/gallery" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Gallery - Desert Safari Dubai" />
        <meta
          property="og:description"
          content="Explore stunning visuals from our Desert Safari Dubai experience. Discover breathtaking landscapes, exhilarating activities, and unforgettable moments."
        />
        <meta
          property="og:url"
          content="https://supersafaridubai.com/gallery-desert-safari"
        />
        <meta
          property="og:image"
          content="https://supersafaridubai.com/path-to-your-image.jpg"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Gallery - Desert Safari Dubai" />
        <meta
          name="twitter:description"
          content="Explore stunning visuals from our Desert Safari Dubai experience. Discover breathtaking landscapes, exhilarating activities, and unforgettable moments."
        />
        <meta
          name="twitter:image"
          content="https://supersafaridubai.com/path-to-your-image.jpg"
        />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ImageGallery",
              "name": "Desert Safari Dubai Gallery",
              "description": "A collection of stunning images from our Desert Safari experiences in Dubai.",
              "image": [
                "https://supersafaridubai.com/path-to-image1.jpg",
                "https://supersafaridubai.com/path-to-image2.jpg",
                "https://supersafaridubai.com/path-to-image3.jpg"
              ]
            }
          `}
        </script>
      </Helmet>

      <Breadcrumbs title="Gallery" pagename="Gallery" />
      <section className="py-5" style={{ overflow: "hidden" }}>
        <Container>
          <Row>
            <Col>
              <Gallery />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PhotoGallery;
