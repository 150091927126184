import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet"; // Import Helmet for SEO
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Col, Container, Offcanvas, Row } from "react-bootstrap";
import { popularsData } from "../../utils/data";
import PopularCard from "../../components/Cards/PopularCard";
import Filters from "./Filters";
import "./tours.css";

// Function to parse price range
const parsePriceRange = (rangeString) => {
  if (typeof rangeString !== "string") {
    console.error("Invalid price range string:", rangeString);
    return [0, Infinity]; // Return a default range
  }

  const match = rangeString.match(/AED\s*(\d+)\s*-\s*AED\s*(\d+)/);
  if (match) {
    return [parseInt(match[1]), parseInt(match[2])];
  }

  console.error("Failed to parse price range:", rangeString);
  return [0, Infinity]; // Return a default range
};

// Function to parse duration
const parseDuration = (durationString) => {
  if (typeof durationString !== "string") {
    console.error("Invalid duration string:", durationString);
    return 0; // Return a default value
  }

  const match = durationString.match(/(\d+)\s*hours/);
  if (match) {
    return parseInt(match[1]);
  }

  console.error("Failed to parse duration:", durationString);
  return 0; // Return a default value
};

const Tours = () => {
  useEffect(() => {
    document.title = "Tours";
    window.scroll(0, 0);
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // State for filters
  const [selectedDuration, setSelectedDuration] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState([]);

  // Function to filter the popularsData based on the selected filters
  const filterTours = () => {
    return popularsData.filter((tour) => {
      const matchDuration =
        selectedDuration.length === 0 ||
        selectedDuration.some((durationString) => {
          const selectedDurationHours = parseDuration(durationString);
          const tourDurationHours = parseDuration(tour.days);
          return tourDurationHours === selectedDurationHours;
        });

      const matchPriceRange =
        selectedPriceRange.length === 0 ||
        selectedPriceRange.some((rangeString) => {
          const [minPrice, maxPrice] = parsePriceRange(rangeString);
          return tour.price >= minPrice && tour.price <= maxPrice;
        });

      return matchDuration && matchPriceRange;
    });
  };

  // Get the filtered tours data
  const filteredTours = filterTours();

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Tours - Discover the Best Desert Safari Experiences</title>
        <meta
          name="description"
          content="Explore our exclusive tours in Dubai. Find the best deals for desert safaris, city tours, and more. Book your adventure today!"
        />
        <link rel="canonical" href="https://supersafaridubai.com/tours" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Tours - Discover the Best Experiences"
        />
        <meta
          property="og:description"
          content="Explore our exclusive tours in Dubai."
        />
        <meta property="og:url" content="https://supersafaridubai.com/tours" />
        <meta
          property="og:image"
          content="https://supersafaridubai.com/images/tours.jpg"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Tours - Discover the Best Experiences"
        />
        <meta
          name="twitter:description"
          content="Explore our exclusive tours in Dubai."
        />
        <meta
          name="twitter:image"
          content="https://supersafaridubai.com/images/tours.jpg"
        />

        {/* JSON-LD structured data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "TouristAttraction",
              "name": "Dubai Tours",
              "description": "Explore our exclusive tours in Dubai, including desert safaris and city tours.",
              "image": "https://supersafaridubai.com/images/tours.jpg",
              "offers": {
                "@type": "AggregateOffer",
                "offerCount": "${filteredTours.length}",
                "lowPrice": "89",
                "highPrice": "500",
                "priceCurrency": "AED"
              }
            }
          `}
        </script>
      </Helmet>

      <Breadcrumbs title="Tours" pagename="Tours" />
      <section className="py-5 tour_list">
        <Container>
          <Row>
            <Col xl="3" lg="4" md="12" sm="12">
              <div className="d-lg-none d-block">
                <button className="primaryBtn mb-4" onClick={handleShow}>
                  <i className="bi bi-funnel"></i> Filters
                </button>
              </div>
              <div className="filters d-lg-block d-none">
                <Filters
                  setSelectedDuration={setSelectedDuration}
                  setSelectedPriceRange={setSelectedPriceRange}
                />
              </div>
            </Col>
            <Col xl="9" lg="8" md="12" sm="12">
              <Row>
                {filteredTours.length > 0 ? (
                  filteredTours.map((val, inx) => {
                    return (
                      <Col
                        xl={4}
                        lg={6}
                        md={6}
                        sm={6}
                        className="mb-5"
                        key={inx}
                      >
                        <PopularCard val={val} />
                      </Col>
                    );
                  })
                ) : (
                  <p>No tours found matching your criteria.</p>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Filters
            setSelectedDuration={setSelectedDuration}
            setSelectedPriceRange={setSelectedPriceRange}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Tours;
