// destinations img
import tour4 from "../assets/images/tour/Tokyo.jpg";
import tour5 from "../assets/images/tour/bali-1.jpg";
import tour6 from "../assets/images/tour/bangkok.png";
import tour7 from "../assets/images/tour/cancun.png";
import tour8 from "../assets/images/tour/nah-trang.png";
import tour9 from "../assets/images/tour/phuket.png";
import tour10 from "../assets/images/tour/paris.jpg";
import tour11 from "../assets/images/tour/malaysia.png";

// populars img
import vip_desert_safari from "../assets/images/popular/vip_desert_safari.jpg";
import evening_desert_Safari from "../assets/images/popular/evening_desert_safari.jpg";
import Desert_Safari_Quad_Bike_VIP from "../assets/images/popular/Desert Safari_Quad Bike_VIP.jpg";
import desert_safari_with_quad_bike from "../assets/images/popular/desert safari_with_quad_bike.jpg";
import private_desert_safari from "../assets/images/popular/private_desert_safari.webp";
import hummer_desert_safari from "../assets/images/popular/hummer_desert_safari.jpg";
import one_seater_buggy from "../assets/images/popular/1_seater_buggy.jpg";
import two_seater_buggy from "../assets/images/popular/two_seater_buggy.jpg";
import four_seater_buggy from "../assets/images/popular/four_seater_buggy.jpg";
import quad_bike from "../assets/images/popular/350cc_quad_bike.jpg";
import am_buggy from "../assets/images/popular/am_buggy.jpg";

// tour detail img
import image1 from "../assets/images/new/1.jpg";
import image2 from "../assets/images/new/2.jpg";
import image3 from "../assets/images/new/3.jpg";
import image4 from "../assets/images/new/4.jpg";
import image5 from "../assets/images/new/5.jpg";
import image6 from "../assets/images/new/6.jpg";
import image7 from "../assets/images/new/7.jpg";
import image8 from "../assets/images/new/8.jpg";

export const destinationsData = [
  {
    id: 0,
    name: "Dubai Desert Safari",
    tours: "5 tours and activities",
    image: tour5,
    link: "dubai-desert-safari",
    shortDes:
      "Experience the thrill of a lifetime in the Dubai desert with exciting tours and activities.",
  },
  {
    id: 1,
    name: "Morning Desert Safari",
    tours: "3 tours and activities",
    image: tour4,
    link: "morning-desert-safari",
    shortDes:
      "Start your day with an exhilarating morning desert safari in Dubai.",
  },
  {
    id: 2,
    name: "Evening Desert Safari",
    tours: "4 tours and activities",
    image: tour6,
    link: "evening-desert-safari",
    shortDes:
      "Enjoy a magical evening desert safari with stunning sunset views and entertainment.",
  },
  {
    id: 3,
    name: "VIP Desert Safari",
    tours: "4 tours and activities",
    image: tour7,
    link: "vip-desert-safari",
    shortDes:
      "Experience luxury and exclusivity with our VIP desert safari package.",
  },
  {
    id: 4,
    name: "Camel Trekking Safari",
    tours: "3 tours and activities",
    image: tour8,
    link: "camel-trekking-safari",
    shortDes:
      "Embark on an unforgettable camel trekking adventure through the Dubai desert.",
  },
  {
    id: 5,
    name: "Overnight Desert Safari",
    tours: "4 tours and activities",
    image: tour9,
    link: "overnight-desert-safari",
    shortDes:
      "Spend a night under the stars with our exclusive overnight desert safari.",
  },
  {
    id: 6,
    name: "Desert Safari with BBQ Dinner",
    tours: "5 tours and activities",
    image: tour10,
    link: "desert-safari-bbq-dinner",
    shortDes:
      "Combine thrilling desert activities with a delicious BBQ dinner in the desert.",
  },
  {
    id: 7,
    name: "Quad Biking Safari",
    tours: "3 tours and activities",
    image: tour11,
    link: "quad-biking-safari",
    shortDes:
      "Get your adrenaline pumping with an exciting quad biking safari in the desert.",
  },
];

export const popularsData = [
  {
    id: 1,
    title: "Evening Desert Safari",
    image: evening_desert_Safari,
    location: "Dubai, United Arab Emirates",
    category: ["Desert Safari", "Adventure"],
    days: "6 hours",
    price: 89,
    rating: 5,
    reviews: 560,
    included: [
      "Estimated Pickup Time 2:30 PM - 3:30 PM",
      "Pick up and Drop from Hotel / Your Location",
      "Sand Dune Bashing Session (Red Sand) 30 to 40 Minutes Dune Bashing Ride",
      "Arabic Costumes For Pictures*",
      "Smoking Area Access With Facilities",
      "Sand Boarding",
      "Camel Ride",
      "Welcome Arabic Tea or Coffee with dates",
      "Starter Food",
      "Henna Painting",
      "Hubbly Bubbly / Water Pipe",
      "Fire Show live performance",
      "Tanura show (Traditional Folk Dnace)",
      "Delicious live BBQ dinner made at the camp site",
      "Continental Buffet with (veg & non-veg) option",
      "Estimated Dropoff Time 9:00 PM - 9:30 PM",
    ],
  },
  {
    id: 2,
    title: "VIP Desert Safari",
    image: vip_desert_safari,
    location: "Dubai, United Arab Emirates",
    category: ["VIP", "Desert Safari"],
    days: "6 hours",
    price: 150,
    rating: 5,
    reviews: 179,
    included: [
      "Estimated Pickup Time 2:30 PM - 3:30 PM",
      "Pick up and Drop from Hotel / Your Location",
      "Sand Dune Bashing Session (Red Sand) 30 to 40 Minutes Dune Bashing Ride",
      "Sand Boarding",
      "Sun Set Photography",
      "VIP table service",
      "VIP Seating Area at the Camp",
      "Avoid Long Queue",
      "Camel Ride",
      "Sofa Seating and Chair and Table",
      "Traditioal Costumes",
      "Arabic Costumes For Pictures*",
      "Unlimited Softdrinks, Tea, Coffee, Water.",
      "Smoking Area Access With Facilities",
      "Arabic Coffee, and Freash Dates",
      "Hubbly Bubbly / Water Pipe",
      "Henna Painting",
      "Fire Show / Tanura show",
      "Purtggol Show",
      "Unlimited Veg and Non Veg Buffet and BBQ",
      "Estimated Dropoff Time 9:00 PM - 9:30 PM",
    ],
  },

  {
    id: 3,
    title: "Desert Safari with Quad Bike",
    image: desert_safari_with_quad_bike,
    location: "Dubai, United Arab Emirates",
    category: ["Desert Safari", "BBQ"],
    days: "6 hours",
    price: 199,
    rating: 5,
    reviews: 151,
    included: [
      "Estimated Pickup Time 2:30 PM - 3:30 PM",
      "Pick up and Drop from Hotel / Your Location",
      "Sand Dune Bashing Session (Red Sand) 30 to 40 Minutes Dune Bashing Ride",
      "35 - 45 Minutes Quad Bike Ride",
      "ATV Quad Bike Ride – 30 Minutes",
      "Arabic Costumes For Pictures*",
      "Smoking Area Access With Facilities",
      "Sand Boarding",
      "Camel Ride",
      "Welcome Arabic Tea or Coffee with dates",
      "Starter Food / Henna Painting",
      "Hubbly Bubbly / Water Pipe",
      "Fire Show live performance",
      "Tanura show (Traditional Folk Dnace)",
      "Delicious live BBQ dinner made at the camp site",
      "Continental Buffet with (veg & non-veg) option",
      "Estimated Dropoff Time 9:00 PM - 9:30 PM",
    ],
  },
  {
    id: 4,
    title: "Desert Safari + Quad Bike + VIP",
    image: Desert_Safari_Quad_Bike_VIP,
    location: "Dubai, United Arab Emirates",
    category: ["Desert Safari", "Morning"],
    days: "4 hours",
    price: 240,
    rating: 5,
    reviews: 270,
    included: [
      "Estimated Pickup Time 2:30 PM - 3:30 PM",
      "Pick up and Drop from Hotel / Your Location",
      "Sand Dune Bashing Session (Red Sand) 30 to 40 Minutes Dune Bashing Ride",
      "35 - 45 Minutes Quad Bike Ride",
      "VIP table service",
      "VIP Seating Area at the Camp",
      "Avoid Long Queue",
      "Sofa Seating and Chair and Table",
      "ATV Quad Bike Ride – 30 Minutes",
      "Arabic Costumes For Pictures*",
      "Smoking Area Access With Facilities",
      "Sand Boarding",
      "Sun Set Photography",
      "Camel Ride",
      "Welcome Arabic Tea or Coffee with dates",
      "Hubbly Bubbly / Water Pipe",
      "Henna Painting",
      "Fire Show / Tanura show",
      "Purtggol Show",
      "Unlimited Veg and Non Veg Buffet and BBQ",
      "Estimated Dropoff Time 9:00 PM - 9:30 PM",
    ],
  },
  {
    id: 5,
    title: "Private Desert Safari",
    image: private_desert_safari,
    location: "Dubai, United Arab Emirates",
    category: ["Camel Trekking", "Desert Safari"],
    days: "3 hours",
    price: 550,
    rating: 5,
    reviews: 129,
    included: [
      "Estimated Pickup Time 2:30 PM - 3:30 PM",
      "Pick up and Drop from Hotel / Your Location",
      "Sand Dune Bashing Session (Red Sand) 30 to 40 Minutes Dune Bashing Ride",
      "Private car for your friends and family",
      "Sand Boarding",
      "Sun Set Photography",
      "Camel Ride",
      "Arabic Coffee, and Freash Dates",
      "Hubbly Bubblee (Sheesha)",
      "Traditioal Costumes",
      "Unlimited Softdrinks, Tea, Coffee, Water.",
      "Henna Painting",
      "Fire Show",
      "Tanura Show",
      "Purtggol Show",
      "BBQ & Buffet Dinner (Veg & non-veg both available)",
      "Estimated Dropoff Time 9:00 PM - 9:30 PM",
    ],
  },
  {
    id: 6,
    title: "Hummer Desert Safari",
    image: hummer_desert_safari,
    location: "Dubai, United Arab Emirates",
    category: ["Quad Biking", "Desert Safari"],
    days: "4 hours",
    price: 200,
    rating: 5,
    reviews: 140,
    included: [
      "Sand Dune Bashing Session (Red Sand) 30 to 40 Minutes Dune Bashing Ride",
      "Sunset Photographic Opportunity.",
      "Camel Riding.",
      "Henna Designing.",
      "Shisha Facility / ‘Hubbllee Bubblee’ Smoking facility.",
      "Arabic Dress photograph opportunity.",
      "Unlimited Soft drinks, Water, Tea & Coffee.",
      "Sand Boarding.",
      "Quad biking (Optional – Upon request & availability).",
      "BBQ Dinner with Veg & Non Veg dishes from 5* quality caterers.",
      "Tanura show/Fire Show by professional artists.",
      "Separate Toilet facilities for both Men & Women.",
      "Drop back to your hotel or residence in Dubai.",
    ],
  },
  {
    id: 7,
    title: "Single Seater Buggy Tours",
    image: one_seater_buggy,
    location: "Dubai, United Arab Emirates",
    category: ["Sandboarding", "Desert Safari"],
    days: "4 hours",
    price: 600,
    rating: 5,
    reviews: 110,
    included: [
      "Polaris 1000cc | 1 Seater",
      "30 mins Buggy Ride",
      "Free Pickup & Drop off",
      "Red Desert Tours",
      "Guided Tour",
      "Water",
      "Helmet & Goggles",
      "Addon Services",
    ],
  },
  {
    id: 8,
    title: "2 Seater Buggy Tours",
    image: two_seater_buggy,
    location: "Dubai, United Arab Emirates",
    category: ["Sandboarding", "Desert Safari"],
    days: "4 hours",
    price: 700,
    rating: 5,
    reviews: 71,
    included: [
      "Polaris 1000cc | 2 Seater",
      "Free Pickup & Drop off",
      "Red Desert Tours",
      "Guided Tour",
      "Water",
      "Helmet & Goggles",
      "Addon Services",
    ],
  },
  {
    id: 9,
    title: "4 Seater Buggy Tours",
    image: four_seater_buggy,
    location: "Dubai, United Arab Emirates",
    category: ["Sandboarding", "Desert Safari"],
    days: "4 hours",
    price: 800,
    rating: 5,
    reviews: 52,
    included: [
      "Polaris 1000cc | 4 Seater",
      "Free Pickup & Drop off",
      "Red Desert Tours",
      "Guided Tour",
      "Water",
      "Helmet & Goggles",
      "Addon Services",
    ],
  },
  {
    id: 10,
    title: "Canam Buggy Tour",
    image: am_buggy,
    location: "Dubai, United Arab Emirates",
    category: ["Sandboarding", "Desert Safari"],
    days: "4 hours",
    price: 900,
    rating: 5,
    reviews: 65,
    included: [
      "CanAm x3 Turbo Dune Buggy",
      "Red Desert Tours",
      "Guided Tour",
      "Water",
      "Helmet & Goggles",
      "4 Seats (300 AED Extra)",
      "2-4 hours tour",
      "Pickup Time Flexible",
    ],
  },

  {
    id: 11,
    title: "Quad Bike",
    image: quad_bike,
    location: "Dubai, United Arab Emirates",
    category: ["Overnight Safari", "Desert"],
    days: "1 night",
    price: 350,
    rating: 5,
    reviews: 218,
    included: [
      "400CC Quad bike (single)",
      "30 mins",
      "Free Pickup & Drop off",
      "Red Desert Tours",
      "Guided Tour",
      "Water",
      "Helmet & Goggles",
      "Addon Services",
    ],
  },
];

export const tourDetails = {
  title: "Dubai Desert Safari Extravaganza",
  des: `Experience the ultimate adventure with our Dubai Desert Safari Extravaganza. Enjoy thrilling dune bashing, camel rides, sandboarding, and a mesmerizing sunset in the desert. Indulge in a delicious BBQ dinner and traditional entertainment as you soak in the beauty of the Arabian desert.`,
  price: "150.00",
  rating: "4.8",
  reviews: "150 reviews",
  tourInfo: [
    '<strong className="font-bold"> Place Covered:</strong> Dubai Desert',
    '<strong className="font-bold">Duration:</strong> 6 Hours',
    '<strong className="font-bold">Start Point:</strong> Dubai Hotel Pickup',
    '<strong className="font-bold">End Point:</strong> Dubai Hotel Drop-off',
  ],

  highlights: [
    "Thrilling dune bashing and desert driving experience.",
    "Scenic camel ride and breathtaking desert sunset views.",
    "Exciting sandboarding and traditional cultural entertainment.",
    "Delicious BBQ dinner with a variety of dishes and beverages.",
  ],

  itinerary: [
    {
      title: `<span class="me-1 fw-bold">Day 1:</span> Pick-Up and Desert Adventure`,
      des: `Begin your adventure with a pick-up from your Dubai hotel. Experience thrilling dune bashing and sandboarding in the desert. Enjoy a camel ride and capture stunning sunset photos.`,
    },

    {
      title: `<span class="me-1 fw-bold">Day 2:</span> Cultural Experience and BBQ Dinner`,
      des: `Enjoy a traditional desert experience with cultural performances. Relish a sumptuous BBQ dinner with various dishes and beverages. Return to your hotel after an unforgettable evening.`,
    },
  ],

  included: [
    "Hotel pick-up and drop-off in Dubai",
    "Thrilling dune bashing and sandboarding",
    "Camel ride and sunset photography",
    "Traditional cultural performances",
    "BBQ dinner with a variety of dishes",
    "Soft drinks and water",
  ],
  exclusion: [
    "Lunch is not included",
    "Personal expenses and tips",
    "Travel insurance",
    "Any other services not mentioned in the inclusions",
  ],

  images: [
    {
      original: image1,
      thumbnail: image1,
    },
    {
      original: image2,
      thumbnail: image2,
    },
    {
      original: image3,
      thumbnail: image3,
    },
    {
      original: image4,
      thumbnail: image4,
    },
    {
      original: image5,
      thumbnail: image5,
    },
    {
      original: image6,
      thumbnail: image6,
    },
    {
      original: image7,
      thumbnail: image7,
    },
    {
      original: image8,
      thumbnail: image8,
    },
  ],
};

export const location = [
  "Dubai Desert Safari",
  "Morning Desert Safari",
  "Evening Desert Safari",
  "VIP Desert Safari",
  "Camel Trekking Safari",
  "Overnight Desert Safari",
  "Desert Safari with BBQ Dinner",
  "Quad Biking Safari",
];

export const Categories = [
  "Desert Safari",
  "Adventure",
  "Cultural Experience",
  "Luxury",
  "Family Friendly",
];

export const Duration = ["1-3 Hours", "4-6 Hours", "Half Day", "Full Day"];
export const PriceRange = [
  "AED 0 - AED100",
  "AED 101 - AED200",
  "AED 201 - AED300",
  "AED 301 - AED400",
  "AED 401 - AED500",
  "AED 501 - AED600",
  "AED 601 - AED700",
  "AED 701 - AED800",
  "AED 801 - AED900",
];

export const Ratings = ["⭐", "⭐⭐", "⭐⭐⭐", "⭐⭐⭐⭐", "⭐⭐⭐⭐⭐"];
