import React from "react";
import "./callButton.css";

const CallButton = () => {
  return (
    <a href="tel:+971582058098" className="call-button" aria-label="Call Us">
      <i className="fas fa-phone call-icon"></i>
    </a>
  );
};

export default CallButton;
