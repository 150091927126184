import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Destinations from "./pages/Destinations/Destination";
import PhotoGallery from "./pages/PhotoGallery/PhotoGallery";
import Booking from "./pages/Booking/Booking";
import Tours from "./pages/Tours/Tours";
import Header from "./components/Common/Header/Header";
import Footer from "./components/Common/Footer/Footer";
import TourDetails from "./pages/Tours/TourDetails";
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton";
import CallButton from "./components/CallButton/CallButton";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about-us" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="gallery" element={<PhotoGallery />} />
        <Route path="tours" element={<Tours />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />

      <WhatsAppButton />
      <CallButton />
    </>
  );
}

export default App;
