import React from "react";
import { Carousel } from "react-bootstrap";
import sliderImg from "../../assets/images/slider/Slider1.jpg";
import sliderImg1 from "../../assets/images/slider/Slider2.jpg";
import "../Banner/banner.css";

const Banner = () => {
  return (
    <>
      <section className="slider">
        <Carousel variant="dark">
          <Carousel.Item>
            <img
              src={sliderImg}
              className="d-block w-100"
              alt="Desert Safari Adventure"
            />
            <Carousel.Caption>
              <div className="slider_des">
                <h5 className="heading">
                  EMBARK ON A <span>DUBAI DESERT SAFARI</span>
                </h5>
                <p className="sub_text">
                  Discover the thrill of a Dubai Desert Safari with exhilarating
                  dune bashing, camel rides, and breathtaking sunset views. Dive
                  into an unforgettable desert adventure and create lasting
                  memories.
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={sliderImg1}
              className="d-block w-100"
              alt="Magical Desert Experience"
            />
            <Carousel.Caption>
              <div className="slider_des">
                <h5 className="heading">
                  EXPERIENCE THE <span>MAGIC OF DUBAI</span>
                </h5>
                <p className="sub_text">
                  Immerse yourself in the enchanting beauty of the Dubai desert.
                  From thrilling sandboarding to relaxing camel rides, our Dubai
                  Desert Safari offers a perfect escape into the heart of the
                  desert.
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  );
};

export default Banner;
