import React, { useEffect } from "react";
import { Helmet } from "react-helmet"; // Import Helmet
import Banner from "../../components/Banner/Banner";
import AdvanceSearch from "../../components/AdvanceSearch/AdvanceSearch";
import Features from "../../components/Features/Features";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import Gallery from "../../components/Gallery/Gallery";
import Cards from "../../components/Cards/Cards";
import { destinationsData, popularsData } from "../../utils/data";
import PopularCard from "../../components/Cards/PopularCard";

const Home = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>
          Dubai Desert Safari: VIP Packages, Evening Safaris & Best Deals
        </title>
        <meta
          name="description"
          content="Experience Dubai Desert Safari with our exclusive VIP packages. Enjoy evening safaris, dune bashing, camel rides, and more. Book today for an unforgettable adventure."
        />
        <link rel="canonical" href="https://supersafaridubai.com" />

        {/* Robots Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Dubai Desert Safari: VIP Packages, Evening Safaris & Best Deals"
        />
        <meta
          property="og:description"
          content="Discover Dubai Desert Safari like never before! Enjoy thrilling experiences like dune bashing, camel rides, and evening safaris."
        />
        <meta
          property="og:url"
          content="https://supersafaridubai.com/dubai-desert-safari"
        />
        <meta
          property="og:image"
          content="https://supersafaridubai.com/images/desert-safari.jpg"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Dubai Desert Safari: VIP Packages, Evening Safaris & Best Deals"
        />
        <meta
          name="twitter:description"
          content="Experience Dubai Desert Safari with thrilling activities like dune bashing and camel rides. Book now for exclusive deals!"
        />
        <meta
          name="twitter:image"
          content="https://supersafaridubai.com/images/desert-safari.jpg"
        />

        {/* Viewport (for responsive design) */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Language */}
        <meta http-equiv="content-language" content="en" />

        {/* Optional: Include schema.org JSON-LD for rich results */}
        <script type="application/ld+json">
          {`
           {
              "@context": "http://schema.org",
              "@type": "TouristAttraction",
              "name": "Dubai Desert Safari",
              "description": "Discover the thrill of Dubai Desert Safari with exclusive packages, including evening safaris, dune bashing, and camel rides.",
              "image": "https://supersafaridubai.com/static/media/evening_desert_safari.abe17277e5281f046b8f.jpg",
              "specificActivities": ["dune bashing", "camel riding", "sandboarding"],
              "safariTypes": ["Morning", "Evening", "Overnight"],
              "culturalExperience": "Experience Arabian hospitality.",
              "offers": {
                "@type": "Offer",
                "url": "https://supersafaridubai.com",
                "priceCurrency": "AED",
                "price": "89",
                "availability": "InStock",
                "validFrom": "2024-01-01"
              }
           }
  `}
        </script>
      </Helmet>

      <Banner />
      <Features />
      <section className="popular py-5">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading">
                <h1>Popular Desert Safari Activities</h1>
              </div>
            </Col>
          </Row>
          <Row>
            {popularsData.map((val, inx) => {
              return (
                <Col md={6} sm={6} xs={12} className="mb-4" key={inx}>
                  <PopularCard val={val} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>

      <section className="call_us">
        <Container>
          <Row className="align-items-center">
            <Col md="8">
              <h5 className="title">CALL TO ACTION</h5>
              <h2 className="heading">
                READY FOR AN UNFORGETTABLE DESERT SAFARI EXPERIENCE IN DUBAI?
              </h2>
              <p className="text">
                Book your Dubai Desert Safari adventure now with our exclusive
                packages and unforgettable experiences in the heart of the
                desert.
              </p>
            </Col>
            <Col md="4" className="text-center mt-3 mt-md-0">
              <a
                href="tel:+971582058098"
                className="secondary_btn bounce"
                rel="no"
              >
                {" "}
                Contact Us!{" "}
              </a>
            </Col>
          </Row>
        </Container>
        <div className="overlay"></div>
      </section>

      <section className="gallery">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading">
                <h1>Dubai Desert Safari Photo Gallery</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Gallery />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
